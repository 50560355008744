import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: string, args: string): string {
    let returnValue: string;
    if (args === 'symbol') {
      switch (value) {
        case 'EUR':
          returnValue = '€';
          break;
        case 'USD':
        case 'CAD':
          returnValue = '$';
          break;
        case 'YEN':
          returnValue = '¥';
          break;
        default:
          returnValue = value;
          break;
      }
    } else if (args === 'literal') {
      switch (value) {
        case 'EUR':
          returnValue = '€ Euro';
          break;
        case 'USD':
          returnValue = '$ Dollar';
          break;
        case 'CAD':
          returnValue = '$ Canadian Dollar';
          break;
        case 'YEN':
          returnValue = '¥ Yen';
          break;
        default:
          returnValue = value;
          break;
      }
    }
    return returnValue;
  }

}

<div class="modal-header modal-lg">
    <h4 class="modal-title pull-left">{{'app.cgu'|translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()" *ngIf="!cguToBeValidated">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-lg modal-pdf-viewer" >
    <app-pdf-preview [srcPdf]="source"></app-pdf-preview>
  </div>
  <div class="modal-footer modal-lg d-flex justify-content-between" style="justify-content: space-evenly;" *ngIf="cguToBeValidated">
    <button class="btn btn-outline-blue" (click)="refuseCgu()" type="button">
      {{'app.refuse'|translate}}
    </button>
    <button class="btn button-blue" (click)="acceptCgu()" type="button">
      {{'app.accept'|translate}}
    </button>
  </div>

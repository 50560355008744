import {transition, trigger, useAnimation} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import {fadeInAnimation} from '../../animation/common.animation';

@Component({
  selector: 'app-kpi-date-range-picker',
  templateUrl: './kpi-date-range-picker.component.html',
  styleUrls: ['./kpi-date-range-picker.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ])
  ]
})
export class KpiDateRangePickerComponent implements OnInit {
  @Output() dateRangeOutput = new EventEmitter<string[]>();

  @Input()
  monthInterval = 1;

  dateRange: Date[];

  config: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  constructor() {
  }

  ngOnInit(): void {
    this.dateRange = [moment().subtract(this.monthInterval, 'months').set('date', 1).toDate(), moment().set('date', moment().daysInMonth()).toDate()];
    this.config = Object.assign({},
      {
        containerClass: 'theme-red',
        isAnimated: false,
        showWeekNumbers: false
      }
    );
  }

  valueChange(dateRange: Date[]): void {
    this.dateRangeOutput.emit(dateRange.map(date => moment(date.toISOString()).format('YYYY-MM-DD')));
  }

  unselectDateRange(): void {
    this.dateRange = [];
  }
}

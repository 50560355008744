export class AuthorityModel {
  id: string;
  name: string;
}

export class EmployeePostBody {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  title: string;
  authority: AuthorityModel;
}

export class EmployeeWithEntityModel {
  employeeId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  tenantId: string;
  title: string;
  authorities: Authority[];
}

export class Authority {
  id: number;
  name: string;
  companyId: string;

  constructor(id: number, name: string, companyId: string) {
    this.id = id;
    this.name = name;
    this.companyId = companyId;
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnChanges {
  @Input() display: boolean = true;
  @Input() fullscreen: boolean = false;
  @Input() backgroundTransparent: boolean = false;
  constructor(private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    if (this.display) {
      this.spinnerService.show();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.display) {
      if (this.display) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    }
  }
}

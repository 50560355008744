import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PasswordMeterConfigModel} from '../models/password-meter-config.model';
import {PasswordStrengthResultModel} from '../models/password-strength-result.model';
import {PasswordStrengthModel} from '../models/password-strength.model';
import {PasswordMeterService} from '../services/password-meter.service';


@Component({
  selector: 'app-password-meter',
  templateUrl: './password-meter.component.html',
  styleUrls: ['./password-meter.component.scss']
})
export class PasswordMeterComponent implements OnInit, OnChanges {

  @Input() password: string;
  @Input() upperCase = true;
  @Input() specialCharacter = true;
  @Input() numberRequired = 1;
  @Input() length = 8;
  @Input() colorArray: string[];
  @Input() displayMessages = true;

  @Output() passwordValid = new EventEmitter<boolean>();
  @Output() passwordStrength = new EventEmitter<PasswordStrengthModel>();

  passwordStrengthResult: PasswordStrengthResultModel = new PasswordStrengthResultModel();

  constructor(private passwordMeterService: PasswordMeterService) {
  }

  ngOnInit(): void {
    this.initColorArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.password) {
      const password = changes.password.currentValue;
      const passwordMeterConfig: PasswordMeterConfigModel = {
        length: this.length,
        numberRequired: this.numberRequired,
        upperCase: this.upperCase,
        specialCharacter: this.specialCharacter,
        colorArray: this.colorArray
      };
      const checkPasswordResult = this.passwordMeterService.checkPassword(password, passwordMeterConfig);
      this.passwordStrengthResult = checkPasswordResult.passwordStrengthResult;
      //console.log('your strength:', this.passwordStrengthResult);
      this.passwordValid.emit(this.passwordStrengthResult.result === 100);
      this.passwordStrength.emit(checkPasswordResult.passwordStrength);
    }
  }

  private initColorArray(): void {
    const defaultColorArray = [
      '#F53D6B',
      '#ED6932',
      '#F2930E',
      '#EDC31E',
      '#2ECC71'
    ];
    if (!this.colorArray) {
      this.colorArray = defaultColorArray;
    } else {
      this.colorArray.length = 5;
      for (let i = 0; i < defaultColorArray.length; i++) {
        if (!this.colorArray[i] || this.colorArray[i][0] !== '#' || (this.colorArray[i].length !== 7 && this.colorArray[i].length !== 4)) {
          this.colorArray[i] = defaultColorArray[i];
        }
      }
    }
  }
}

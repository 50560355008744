import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-confirm-with-comment',
  templateUrl: './modal-confirm-with-comment.component.html',
  styleUrls: ['./modal-confirm-with-comment.component.scss']
})
export class ModalConfirmWithCommentComponent implements OnInit {

  // Input variables
  message: string;
  additionalMessage: string;
  commentRequired: boolean = false;

  // Output variables
  @Output() actionConfirmed = new EventEmitter<string>();
  @Output() actionCanceled = new EventEmitter();

  // Intern variables
  commentForm: FormGroup;

  constructor(public modalRef: BsModalRef,
              private fb: FormBuilder) {
    this.commentForm = this.fb.group({
      comment: [null, [Validators.required, Validators.maxLength(255)]]
    });
  }

  ngOnInit(): void {
  }

  confirmAction(): void {
    if (this.commentRequired) {
      this.actionConfirmed.emit(this.commentForm.get('comment').value.trim());
    } else {
      this.actionConfirmed.emit();
    }
    this.modalRef.hide();
  }

  cancelAction(): void {
    this.actionCanceled.emit();
    this.modalRef.hide();
  }
}

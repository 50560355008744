<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs ; let last = last" class="breadcrumb-item">
    <ng-container *ngIf="!last; else lastItem">
      <a *ngIf="breadcrumb.url !== '/'" [routerLink]="[breadcrumb.url]">
        {{'core.components.breadcrumb.' + transformTranslationPath(breadcrumb.label) | translate}}
      </a>
      <a *ngIf="breadcrumb.url === '/'" [routerLink]="[breadcrumb.url]"><i class="fa fa-home" aria-hidden="true"></i></a>
    </ng-container>
    <ng-template #lastItem>
      <span>
        {{'core.components.breadcrumb.' + transformTranslationPath(breadcrumb.label) | translate}}
      </span>
    </ng-template>
  </li>
  <ng-container *ngIf="breadcrumbs.length === 0">
    <ng-container *ngIf="isRole(['ROLE_BUYER_ADMIN', 'ROLE_BUYER']); else breadcrumbProjectManager">
      <li class="breadcrumb-item">
        <a [routerLink]="['dashboard']"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        <span>{{'core.components.breadcrumb.item.dashboard'|translate}}</span>
      </li>
    </ng-container>

    <ng-template #breadcrumbProjectManager>
      <li class="breadcrumb-item">
        <a [routerLink]="['my-company']"><i class="fa fa-home" aria-hidden="true"></i></a>
      </li>
      <li class="breadcrumb-item">
        <span>{{'core.components.breadcrumb.item.myCompany'|translate}}</span>
      </li>
    </ng-template>
  </ng-container>
</ol>

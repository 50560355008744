import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'amountFormatter'
})
export class AmountFormatterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      return value;
    }
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {BreadcrumbService} from '../../services/breadcrumb.service';
import {RoleService} from '../../services/role.service';
import {BreadCrumb} from './model/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: BreadCrumb[] = [];
  subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private roleService: RoleService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe((event) => {
        this.breadcrumbs = this.breadcrumbService.buildBreadCrumb(this.activatedRoute.root);
      }
    );
  }

  isRole(role: string | string[]): boolean {
    return this.roleService.isRole(role);
  }

  transformTranslationPath(urlName: string) {
    return urlName.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

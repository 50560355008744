export class EmployeeAdvancedSearchModel {
  firstName: string;
  lastName: string;
  email: string;
  disabled: boolean

  constructor(object?: any) {
    if (object) {
      this.firstName = object.firstName;
      this.lastName = object.lastName;
      this.email = object.email;
      this.disabled = object.disabled;
    }
  }
}

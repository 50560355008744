import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-user-ribbon',
  templateUrl: './user-ribbon.component.html',
  styleUrls: ['./user-ribbon.component.scss']
})
export class UserRibbonComponent implements OnInit {

  text: string;

  constructor() {
  }

  ngOnInit() {
    this.text = 'Buyer platform';
  }

}

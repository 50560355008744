import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noDecimal'
})
export class NoDecimalPipe implements PipeTransform {

  transform(value: any): any {
    // Delete all characters after "."
    return value ? value.substring(0, value.indexOf('.')) : ''
  }

}

<div class="card box-shadow-hover">
  <div class="card-body">
    <div class="title">{{title}}</div>
    <div class="unit">{{max | noDecimal | default : '0'}} {{unit}}</div>
    <div class="mb-2">
      <progressbar class="bar-properties"
                   [animate]="true" [type]="colorBar"
                   [value]="value | trim | round" [max]="max | trim | round"
                   [tooltip]="(value | noDecimal) + ' / ' + (max | noDecimal)" placement="bottom">
        <strong>{{(value | noDecimal | default: '0') + '&nbsp;' + unit}}</strong>
      </progressbar>
      <span class="percent">{{((((value | trim)/ (max | trim)*100) |number: '1.0-0') | default : '0') + ' %'}}</span>
    </div>
  </div>
</div>

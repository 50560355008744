<div class="modal-body p-5">
  <div class="icon-warning {{blueStyle? 'blue-exclamation-mark': ''}}">
    <div class="icon-warning-content ">!</div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="font-weight-bold mb-2">{{message}}</h4>
    <p class="text-dark-gray mb-0" *ngIf="additionalMessage">{{additionalMessage}}</p>
    <div class="mt-4">
      <button class="btn btn-outline-warning px-5 mr-3 {{blueStyle? 'btn-outline-blue': ''}}" (click)="cancelAction()">{{'global.button.cancel'|translate}}</button>
      <button class="btn btn-primary px-5  {{blueStyle? 'button-blue': ''}}" (click)="confirmAction()">{{'global.button.yes-i-confirm'|translate}}</button>
    </div>
  </div>
</div>

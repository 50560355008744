import { Injectable } from '@angular/core';
import {BillingAddressModel} from '../models/billing-address.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../assets/environments/environment';
import {commonProperties} from '../../../../../../assets/environments/environment.common';
import {AuthService} from '../../../../../core/services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class BillingAddressService {

  constructor(private _httpClient: HttpClient, private authService: AuthService) { }

  createBillingAddress(params: BillingAddressModel): Observable<BillingAddressModel> {
    return this._httpClient.post<BillingAddressModel>(environment.api_root + commonProperties.billingAddress.replace(':companyId', this.authService.getCurrentCompanyId()), params);
  }

  retrieveBillingAddresses(active: boolean): Observable<BillingAddressModel[]> {
    const params = { active };
    return this._httpClient.get<BillingAddressModel[]>(environment.api_root + commonProperties.billingAddress.replace(':companyId', this.authService.getCurrentCompanyId()), {params});
  }

  retrieveBillingAddress(addressId: string): Observable<BillingAddressModel> {
    return this._httpClient.get<BillingAddressModel>(environment.api_root + commonProperties.billingAddressId.replace(':companyId', this.authService.getCurrentCompanyId()).replace(':id', addressId));
  }

  updateBillingAddress(params: BillingAddressModel): Observable<BillingAddressModel> {
    return this._httpClient.put<BillingAddressModel>(environment.api_root + commonProperties.billingAddressId.replace(':companyId', this.authService.getCurrentCompanyId()).replace(':id', params.id), params);
  }

  disableBillingAddress(params: {addressId: string}): Observable<any> {
    return this._httpClient.put<any>(
      environment.api_root + commonProperties.billingAddressDisable
        .replace(':companyId', this.authService.getCurrentCompanyId())
        .replace(':addressId', params.addressId),
      {}
    );
  }

}

import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {JwtModule} from '@auth0/angular-jwt';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {HeaderComponent} from './components/header/header.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {AuthService} from './services/auth-service.service';
import {JwtInterceptorService} from './services/jwt-interceptor.service';
import {SharedModule} from '../shared/shared.module';
import { KpisInterceptorService } from './services/kpis-interceptor.service';
import {HeadersInterceptorService} from './services/headers-interceptor.service';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [HeaderComponent, NavbarComponent, BreadcrumbComponent],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter
            }
        }),
        SharedModule,
        FontAwesomeModule
    ],
  exports: [
    HeaderComponent,
    NavbarComponent,
    BreadcrumbComponent
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KpisInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationModule} from './authentication/authentication.module';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {ActivityReportModule} from './activity-report/activity-report.module';
import {commonProperties} from '../assets/environments/environment.common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {TranslateStore} from '@ngx-translate/core';
import {environment} from '../assets/environments/environment';
import * as Sentry from '@sentry/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeFr);

export function initConfig(injector: Injector, logger: NGXLogger) {
  return () => {
      if (!environment.production) {
        const updatedConfig = logger.getConfigSnapshot();
        updatedConfig.level = NgxLoggerLevel.ERROR;
        logger.updateConfig(updatedConfig);
      }
     const jwtHelper = injector.get(JwtHelperService);
      const router = injector.get(Router);
      const token = localStorage.getItem(commonProperties.token);
      if (token) {
        if (jwtHelper.isTokenExpired()) {
          // console.log('app initializer : token expired');
          localStorage.removeItem('access_token');
          router.navigate(['login']);
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', environment.api_root + commonProperties.tokenValidation);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 204) {
            localStorage.removeItem('access_token');
            router.navigate(['login']);
          }
        });

        xhr.send(JSON.stringify({
          token,
          type: 'BUYER'
        }));
      }
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthenticationModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      timestampFormat: 'dd/MM/yyyy HH:mm:ss',
    }),
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [Injector, NGXLogger]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="modal-body p-5">
    <div class="icon-warning">
      <div class="icon-warning-content">!</div>
    </div>
    <ng-container>
        <form [formGroup]="negotiationForm">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <h4 class="font-weight-bold mb-2">{{message}}</h4>

                <div class="form-group w-100 mt-3 mb-1 d-flex justify-content-center ">
                    <span>
                        {{'marketplace.call-for-tenders-offers.propose-new-amount'|translate}}
                    </span>
                    <div>
                      <div class="input-group-append mr-2 ml-2">
                        <input type="number" class="form-control-md" id="negotiateAmount" formControlName="amount" placeholder={{defaultAmount}}>
                        <span class="input-group-text to-neg-curr">{{currency | currencyFormat: 'symbol'}}</span>
                      </div>
                      <div class="ml-2">
                        <app-form-feedback
                          *ngIf="negotiationForm.get('amount').errors?.required && (negotiationForm.get('amount').dirty || negotiationForm.get('amount').touched)"
                          message="{{'global.feedback.required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback *ngIf="negotiationForm.errors?.amountDefault"></app-form-feedback>
                        <app-form-feedback *ngIf="negotiationForm.errors?.amountRange"></app-form-feedback>
                      </div>
                    </div>
                    <span>
                        {{'marketplace.call-for-tenders-offers.between-min-max'|translate : {min: min, max: max} }}
                    </span>
                </div>
                <div class="mt-2" *ngIf="negotiationLevel == 1">
                    <button class="btn btn-outline-warning px-5 mr-3" type="button" (click)="cancelAction()">{{'global.button.cancel'|translate}}</button>
                    <button class="btn btn-primary px-5" type="submit" (click)="negotiateAction()"  [disabled]="!negotiationForm.valid">{{'global.button.yes-i-confirm'|translate}}</button>
                </div>
                <div class="mt-2" *ngIf="negotiationLevel == 3">
                    <button class="btn btn-outline-primary px-3 mr-3" type="button" (click)="cancelAction()">{{'global.button.cancel'|translate}}</button>
                    <button class="btn btn-outline-info px-3 mr" type="submit" (click)="negotiateAction()"  [disabled]="!negotiationForm.valid">{{'marketplace.call-for-tenders-offers.negotiate'|translate}}</button>
                </div>
            </div>
        </form>
    </ng-container>
  </div>

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KpisService {

  updateEvent = new EventEmitter<{type: string, result: number}>();

  constructor(private httpClient: HttpClient) { }

  async getSubmittedFeeCount(): Promise<void> {
    const result = await this.httpClient.get<number>(environment.api_root + commonProperties.submittedFeeCount).toPromise();
    this.updateEvent.emit({type: 'fee', result});
  }

  async getSubmittedOnCallCount(): Promise<void> {
    const result = await this.httpClient.get<number>(environment.api_root + commonProperties.submittedOnCallCount).toPromise();
    this.updateEvent.emit({type: 'oncall', result});
  }

  async getSubmittedActivitySheetCount(): Promise<void> {
    const result = await this.httpClient.get<number>(environment.api_root + commonProperties.submittedActivitySheetCount).toPromise();
    this.updateEvent.emit({type: 'activitysheet', result});
  }
}

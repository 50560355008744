<pdf-viewer class="pdf-viewer"
            src="{{srcPdf}}"
            [show-all]="false"
            [original-size]="false"
            [(page)]="numPage"
            (after-load-complete)="afterLoadComplete($event)"
            [style]="inStyle"
            >
</pdf-viewer>
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="totalPages > 1">
  <button class="btn btn-light" (click)="decrement()" [disabled]="numPage === 1">{{'shared.components.pdf-preview.button.previous-page'|translate}}</button>
  <span class="h4 mx-1">{{numPage}}/{{totalPages}}</span>
  <button class="btn btn-light" (click)="increment()" [disabled]="numPage === totalPages">{{'shared.components.pdf-preview.button.next-page'|translate}}</button>
</div>





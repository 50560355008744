import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {

  @Input() srcPdf;
  numPage: number = 1;
  totalPages: number;
  @Input() inStyle: string;

  constructor() {
  }

  ngOnInit() {
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  increment() {
    this.numPage++;
  }

  decrement() {
    this.numPage--;
  }


}

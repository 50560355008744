<div class="row mt-4">
  <div class="col">
    <div class="text-center">
      <i [ngClass]="iconClassName" class="icon-no-content" aria-hidden="true"></i>
    </div>
    <div class="no-content-container">
      <p class="font-weight-bold mb-4">{{content}}</p>
      <a class="btn btn-primary px-5" *ngIf="buttonContent" [routerLink]="link">{{buttonContent}}</a>
    </div>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  message: string;
  additionalMessage: string;
  blueStyle = false;

  @Output() actionConfirmed = new EventEmitter<boolean>();
  @Output() actionCanceled = new EventEmitter<boolean>();

  constructor(public modalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  confirmAction(): void {
    this.actionConfirmed.emit(true);
    this.modalRef.hide();
  }

  cancelAction(): void {
    this.actionCanceled.emit(true);
    this.modalRef.hide();
  }
}

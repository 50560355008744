<div class="mb-3">
  <strong>
    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
    {{'shared.components.kpi-date-range-picker.date-range'|translate}}
  </strong>
  <span bsDaterangepicker [bsConfig]="config"
        (bsValueChange)="valueChange($event)" [(bsValue)]="dateRange"
        placement="right" class="text-link-info">
    <ng-container *ngIf="dateRange.length === 0">
      <span @fadeIn>{{'shared.components.kpi-date-range-picker.select-a-custom-range'|translate}}</span>
    </ng-container>

    <ng-container *ngIf="dateRange.length > 0">
      <span @fadeIn>{{dateRange[0] | date: 'dd/MM/yyyy'}} - {{dateRange[1] | date: 'dd/MM/yyyy'}}</span>
    </ng-container>
  </span>
  <span class="cursor-pointer ml-1" (click)="unselectDateRange()" title="Unselect custom range"
        *ngIf="dateRange.length > 0" @fadeIn>
    <i class="fa fa-times" aria-hidden="true"></i>
  </span>
</div>

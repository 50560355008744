import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import {AuthService} from '../../services/auth-service.service';
import { KpisService } from '../../services/kpis.service';
import {RoleService} from '../../services/role.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

  feeCount = 0;
  OnCallCount = 0;
  ActivitySheetCount = 0;

  updateSubscription: Subscription = null;

  @ViewChildren(BsDropdownDirective) dropdownList: QueryList<BsDropdownDirective>;

  constructor(private authService: AuthService, private roleService: RoleService, private kpiService: KpisService) {
  }

  ngOnDestroy(): void {
    if (this.updateSubscription !== null) {
      this.updateSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.isRole('PROJECT_MANAGER')) {
      this.updateSubscription = this.kpiService.updateEvent.subscribe((result: {type: string, result: number}) => {
        switch (result.type) {
          case 'fee':
            this.feeCount = result.result;
            break;
          case 'oncall':
            this.OnCallCount = result.result;
            break;
          case 'activitysheet':
            this.ActivitySheetCount = result.result;
            break;
        }
      });
      this.kpiService.getSubmittedFeeCount();
      this.kpiService.getSubmittedOnCallCount();
      this.kpiService.getSubmittedActivitySheetCount();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dropdownList.forEach(dropdown => {
        dropdown.show();
        setTimeout(() => {
          dropdown.hide();
        });
      });
    });
  }

  isCompleted() {
    return !(this.authService.getCompanyStatus() === 'IN_PROGRESS' || this.authService.getCompanyStatus() === 'SUBMITTED');
  }

  isRole(role: string | string[]): boolean {
    return this.roleService.isRole(role);
  }
}

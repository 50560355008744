import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'negotiation-modal',
  templateUrl: './negotiation-modal.component.html',
  styleUrls: ['./negotiation-modal.component.scss']
})
export class NegotationModalComponent {
  message: string;
  min: number = 0;
  max: number = 0;
  defaultAmount: number;
  negotiationLevel: number;
  currency: string;

  @Output() actionConfirmed = new EventEmitter<boolean>();
  @Output() actionNegotiate = new EventEmitter<boolean>();
  @Output() actionCanceled = new EventEmitter<boolean>();

  // Intern variables
  negotiationForm: FormGroup;

  constructor(public modalRef: BsModalRef, private fb: FormBuilder) {
    this.negotiationForm = this.fb.group({
      amount: [null, [Validators.required, this.checkAmountDefault, this.checkAmountRange]]
    });
  }

  confirmAction(): void {
    this.actionConfirmed.emit(this.negotiationForm.get('amount').value);
    this.modalRef.hide();
  }

  negotiateAction(): void {
    this.actionNegotiate.emit(this.negotiationForm.get('amount').value);
    this.modalRef.hide();
  }

  cancelAction(): void {
    this.actionCanceled.emit(true);
    this.modalRef.hide();
  }

  private checkAmountDefault = (control: FormControl) => {
    const amount = control?.value;
    if (amount == this.defaultAmount) {
      return {amountDefault: true};
    }
    return null;
  }

  private checkAmountRange = (control: FormControl) => {
    const amount = control?.value;
    if (amount > this.max || amount < this.min) {
      return {amountRange: true};
    }
    return null;
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountValidationComponent} from './account-validation/account-validation.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {UnauthGuard} from '../core/guards/unauth.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [UnauthGuard],
    component: LoginComponent,
  },
  {
    path: 'forget-password',
    canActivate: [UnauthGuard],
    component: ForgetPasswordComponent,
  },
  {
    path: 'account-validation',
    canActivate: [UnauthGuard],
    component: AccountValidationComponent,
  },
  {
    path: 'reset-password',
    canActivate: [UnauthGuard],
    component: ResetPasswordComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  api_root: 'https://api.qa.isupplier.fr/',
  superset_root: 'https://bi.tools.qa.isupplier.fr',
  display_demo: true

};

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'monthName'
})

export class MonthNamePipe implements PipeTransform {
    transform(value: number): string {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Subtract 1 from the value since the array is zero-based
        const index = value - 1;

        if (index >= 0 && index < months.length) {
            return months[index];
        }

        return '';
      }
}
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../regex/form.regex';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscriber, Subscription} from 'rxjs';
import {BillingAddressModel} from '../dropdowns/billing-address-dropdown/models/billing-address.model';
import {BillingAddressService} from '../dropdowns/billing-address-dropdown/services/billing-address.service';

@Component({
  selector: 'app-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss']
})
export class BillingAddressFormComponent implements OnInit {
  @Input() isLegalInfoUpdate: boolean = false;
  @Output() refreshTable = new EventEmitter();
  @Output() cancelForm = new EventEmitter();
  @Output() validationData: EventEmitter<{ isValid: boolean, data: BillingAddressModel }> = new EventEmitter<{
    isValid: boolean;
    data: BillingAddressModel
  }>();
  private readonly companyId: string;
  billingAddressForm: FormGroup;
  isUpdateMode: boolean = false;
  subscriptions: Subscription[] = [];


  constructor(private _formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private service: BillingAddressService,
              private toastr: ToastrService,
              private translateService: TranslateService) {
    this.companyId = this.activatedRoute.parent.snapshot.paramMap.get('companyId');
    this.initForm();
  }

  initForm() {
    this.billingAddressForm = this._formBuilder.group({
      id: [''],
      companyId: this.companyId,
      name: ['', [Validators.required, Validators.pattern(regex.street), Validators.maxLength(75)]],
      recipientName: [''],
      street1: ['', [Validators.required, Validators.pattern(regex.street), Validators.maxLength(100)]],
      street2: ['', [Validators.pattern(regex.street), Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.pattern(regex.city), Validators.maxLength(60)]],
      stateOrProvince: ['', [Validators.pattern(regex.stateOrProvince), Validators.maxLength(60)]],
      country: ['', [Validators.required, Validators.pattern(regex.country)]],
      postCode: ['', [Validators.pattern(regex.internationalPostalCode), Validators.minLength(3), Validators.maxLength(9), Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.isLegalInfoUpdate) {
      this.isUpdateMode = true;
      this.subscriptions.push(this.billingAddressForm.valueChanges.subscribe(() => {
        this.validationData.emit({isValid: this.billingAddressForm.valid, data: this.billingAddressForm.value});
      }));
    }
  }

  cancelFormChanges() {
    this.billingAddressForm.reset();
    this.isUpdateMode = false;
    this.cancelForm.emit();
  }

  submit() {
    this.submitBillingAddressForm().subscribe(value => {
      this.resetForm();
    });
  }

  submitBillingAddressForm(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      if (!this.billingAddressForm.get('id').getRawValue()) {
        this.createBillingAddress().subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        });
      } else {
        this.updateBillingAddress().subscribe(value => {
          this.cancelForm.emit();
          subscriber.next(value);
          subscriber.complete();
        });
      }
    });
  }

  private afterCreateOrUpdate(address: BillingAddressModel, subscriber: Subscriber<BillingAddressModel>, toastrKey: string) {
    if (!this.isLegalInfoUpdate) {
      this.resetForm();
    } else {
      this.billingAddressForm.patchValue(address);
    }
    this.refreshTable.emit();
    if (!this.isLegalInfoUpdate) {
      this.toastr.success(this.translateService.instant(toastrKey));
    }
    subscriber.next(address);
  }

  private updateBillingAddress(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      this.service.updateBillingAddress(this.billingAddressForm.value).subscribe({
        next: (address: BillingAddressModel) => this.afterCreateOrUpdate(address, subscriber, 'my-company.update-company.billing-address.update-billing-address.success'),
        error: (err) => this.handleErrors(err)
      });
    });
  }

  private createBillingAddress(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      this.service.createBillingAddress(this.billingAddressForm.value).subscribe({
        next: (address: BillingAddressModel) => this.afterCreateOrUpdate(address, subscriber, 'my-company.update-company.billing-address.create-billing-address.success'),
        error: (err) => this.handleErrors(err)
      });
    });
  }

  private handleErrors(err: { error: { status: number; title: string; }; }) {
    const { status, title } = err?.error || {};
    if (status === 409) {
      switch (title) {
        case 'ADDRESS_ALREADY_EXISTS':
          this.toastr.info(this.translateService.instant('my-company.update-company.billing-address.feedback.conflict-details'));
          break;
        case 'DEFAULT_ADDRESS_UPDATE_BLOCKED':
          this.toastr.info(this.translateService.instant('my-company.update-company.billing-address.feedback.conflict-details-default'));
          break;
        default:
          this.toastr.error(this.translateService.instant('global.ts.error'));
          break;
      }
    } else {
      this.toastr.error(this.translateService.instant('global.ts.error'));
    }
  }

  resetForm() {
    this.initForm();
  }
}

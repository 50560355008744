import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'my-company',
     loadChildren: () => import('./my-company/my-company.module').then(m => m.MyCompanyModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    data: {
      breadcrumb: 'Profile',
    }
  },
  {
    path: 'employees',
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    data: {
      breadcrumb: 'Employees'
    }
  },
  {
    path: 'request',
    loadChildren: () => import('./request/request.module').then(m => m.RequestModule),
    data: {
      breadcrumb: 'Request'
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('./gta/gta.module').then(m => m.GtaModule),
    data: {
      breadcrumb: 'My Projects'
    }
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
    data: {
      breadcrumb: 'Marketplace',
    }
  },
  {
    path: 'activity-reports',
    loadChildren: () => import('./activity-report/activity-report.module').then(m => m.ActivityReportModule),
    data: {
      breadcrumb: 'Activity-reports',
    }
  },
  {
    path: 'fees',
    loadChildren: () => import('./fees/fees.module').then(m => m.FeesModule),
    data: {
      breadcrumb: 'Fees',
      expectedRoles: ['ROLE_PROJECT_MANAGER'],
    },
  },
  {
    path: 'on-call',
    loadChildren: () => import('./on-call/on-call.module').then(m => m.OnCallModule),
    data: {
      breadcrumb: 'On-call',
      expectedRoles: ['ROLE_PROJECT_MANAGER'],
    },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

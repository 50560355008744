<div class="sidebar border-right">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-title">
        {{'core.components.navbar.dashboard'|translate}}
      </li>
      <ng-container *ngIf="isRole(['ROLE_BUYER', 'ROLE_BUYER_ADMIN'])">
        <li [ngClass]="{'disabled': !isCompleted()}" class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['dashboard/activity']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon icon-speedometer"
                aria-hidden="true"></em>{{'core.components.navbar.activity'|translate}}
          </a>
        </li>
      </ng-container>
      <li [ngClass]="{'disabled': !isCompleted()}" class="nav-item" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" [routerLink]="['dashboard/budget']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <em class="nav-icon fa fa-money-bill"
              aria-hidden="true"></em>{{'core.components.navbar.budget'|translate}}
        </a>
      </li>
      <li class="nav-title">
        {{'core.components.navbar.employees'|translate}}
      </li>
      <li [ngClass]="{'disabled': !isCompleted()}" class="nav-item" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" [routerLink]="['employees']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: false}">
          <em class="nav-icon fa icon-people"
              aria-hidden="true"></em> {{'core.components.navbar.employees'|translate}}
        </a>
      </li>

      <ng-container *ngIf="isRole('ROLE_PROJECT_MANAGER')">
        <li class="nav-title">
          {{'core.components.navbar.projects-title'|translate}}
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['activity-reports/projects']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}">
            <em class="nav-icon fa icon-calendar" aria-hidden="true"></em>
            {{'core.components.navbar.projects.activity_report'|translate}}
            <span class="badge badge-primary" *ngIf="ActivitySheetCount > 0">{{ActivitySheetCount}}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link"
             [routerLink]="['projects/due-payment-validation', 'FIXED_PRICE']"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}">
            <em class="nav-icon fa icon-calendar"
                aria-hidden="true"></em> {{'core.components.navbar.projects.fixed-price'|translate}}
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['projects/due-payment-validation', 'BILL_ONLY']"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}">
            <em class="nav-icon fa icon-calendar"
                aria-hidden="true"></em> {{'core.components.navbar.projects.bill-only'|translate}}
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['fees']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa icon-wallet"
                aria-hidden="true"></em> {{'core.components.navbar.projects.fees'|translate}}
                <span class="badge badge-primary" *ngIf="feeCount > 0">{{feeCount}}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['on-call']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa icon-doc"
                aria-hidden="true"></em> {{'core.components.navbar.projects.on-call'|translate}}
                <span class="badge badge-primary" *ngIf="OnCallCount > 0">{{OnCallCount}}</span>
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="isCompleted()">
        <li class="nav-title">
          {{'core.components.navbar.requests-title'|translate}}
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['request/list']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-suitcase"
                aria-hidden="true"></em>{{'core.components.navbar.requests.list'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}"  *ngIf="isRole(['ROLE_BUYER', 'ROLE_BUYER_ADMIN', 'READ_REQUESTS', 'WRITE_REQUESTS'])">
          <a class="nav-link" [routerLink]="['request/draft']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-suitcase"
                aria-hidden="true"></em>{{'core.components.navbar.requests.draft'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: false}"  *ngIf="isRole(['ROLE_BUYER', 'ROLE_BUYER_ADMIN', 'READ_REQUESTS', 'WRITE_REQUESTS'])">
          <a class="nav-link" [routerLink]="['request/creation']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-suitcase"
                aria-hidden="true"></em>{{'core.components.navbar.requests.creation'|translate}}</a>
        </li>
      </ng-container>


      <ng-container *ngIf="isRole(['ROLE_BUYER', 'ROLE_BUYER_ADMIN', 'PROJECT_MANAGER']) && isCompleted()">
        <li class="nav-title">
          {{'core.components.navbar.marketplace-title'|translate}}
        </li>
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['/marketplace/calls-for-tender']"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa-regular fa-newspaper"
                aria-hidden="true"></em>{{'core.components.navbar.marketplace.calls-for-tender'|translate}}
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="isRole(['ROLE_BUYER', 'ROLE_BUYER_ADMIN', 'WRITE_CFT'])"
            [routerLinkActiveOptions]="{exact: false}">
          <a class="nav-link" [routerLink]="['/marketplace/leagues']" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: false}">
            <em class="nav-icon fa fa-institution"
                aria-hidden="true"></em>{{'core.components.navbar.marketplace.leagues'|translate}}
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>

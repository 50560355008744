import {Injectable} from '@angular/core';
import {PasswordMeterConfigModel} from '../models/password-meter-config.model';
import {PasswordStrengthResultModel} from '../models/password-strength-result.model';
import {PasswordStrengthModel} from '../models/password-strength.model';
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class PasswordMeterService {

  constructor(private translateService: TranslateService,) {

  }

  checkPassword(password: string, passwordMeterConfig: PasswordMeterConfigModel): { passwordStrengthResult: PasswordStrengthResultModel, passwordStrength: PasswordStrengthModel } {
    let value = 1;
    let numberOfTest = 1;
    const passwordStrengthResult = new PasswordStrengthResultModel();
    const passwordStrength = new PasswordStrengthModel();

    if (password) {
      if (password.length === 0) {
        return {passwordStrengthResult, passwordStrength};
      }

      if (passwordMeterConfig.length > 0) {
        numberOfTest++;
        if (this.checkLength(password, passwordMeterConfig.length)) {
          value++;
          passwordStrength.length = true;
        } else {
          if (passwordMeterConfig.length > 1) {
            this.translateService.get('authentication.services.password-meter-ts.feedback-length-plural', {length: passwordMeterConfig.length}).subscribe((data: any) => {
              passwordStrengthResult.messages.push(data);
            });
          } else {
            this.translateService.get('authentication.services.password-meter-ts.feedback-length-singular').subscribe((data: any) => {
              passwordStrengthResult.messages.push(data);
            });
          }
        }

      }

      if (passwordMeterConfig.upperCase) {
        numberOfTest++;
        if (this.checkUpperCase(password)) {
          value++;
          passwordStrength.upperCase = true;
        } else {
          this.translateService.get('authentication.services.password-meter-ts.feedback-uppercase').subscribe((data: any) => {
            passwordStrengthResult.messages.push(data);
          });
        }
      }

      if (passwordMeterConfig.specialCharacter) {
        numberOfTest++;
        if (this.checkSpecialCharacter(password)) {
          value++;
          passwordStrength.specialCharacter = true;
        } else {
          this.translateService.get('authentication.services.password-meter-ts.feedback-special').subscribe((data: any) => {
            passwordStrengthResult.messages.push(data);
          });
        }
      }

      if (passwordMeterConfig.numberRequired > 0) {
        numberOfTest++;
        if (this.checkNumber(password, passwordMeterConfig.numberRequired)) {
          value++;
          passwordStrength.numberRequired = true;
        } else {
          if (passwordMeterConfig.numberRequired > 1) {
            this.translateService.get('authentication.services.password-meter-ts.feedback-number-plural', {number: passwordMeterConfig.numberRequired}).subscribe((data: any) => {
              passwordStrengthResult.messages.push(data);
            });
          } else {
            this.translateService.get('authentication.services.password-meter-ts.feedback-number-singular').subscribe((data: any) => {
              passwordStrengthResult.messages.push(data);
            });
          }
        }
      }

      passwordStrengthResult.result = Math.round((value / numberOfTest) * 100);
      passwordStrengthResult.color = this.getColor(value, numberOfTest, passwordMeterConfig.colorArray);
    }
    return {passwordStrengthResult, passwordStrength};

  }

  private checkLength(password: string, length: number): boolean {
    return password.length >= length;
  }

  private checkUpperCase(password: string): boolean {
    return password !== password.toLowerCase();
  }

  private checkSpecialCharacter(password): boolean {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  }

  private checkNumber(password: string, numberRequired: number): boolean {
    if (numberRequired === 0) {
      return true;
    }
    const numberMatch = password.match(/\d/g);
    if (numberMatch) {
      return numberMatch.length >= numberRequired;
    }
    return false;
  }

  private getColor(value: number, numberOfTest: number, colorArray: string[]): string {
    let index: number;
    if (value === numberOfTest) {
      index = 4;
    } else {
      index = value - 1;
    }

    return colorArray[index];
  }
}

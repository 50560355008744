import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-kpi-progress-bar',
  templateUrl: './kpi-progress-bar.component.html',
  styleUrls: ['./kpi-progress-bar.component.scss']
})
export class KpiProgressBarComponent {
  @Input() title: string;
  @Input() value: number;
  @Input() max: number;
  @Input() colorBar: string;
  @Input() unit: string;
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth-service.service';
import {commonProperties} from '../../../assets/environments/environment.common';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

  constructor(public auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.isAuthenticated()) {
      req = req.clone({
        setHeaders: {
          Authorization: localStorage.getItem(commonProperties.token)
        }
      });
    }
    return next.handle(req);
  }
}


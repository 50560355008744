<header class="app-header navbar">
  <button class="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button"
          data-toggle="sidebar-show">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand ">
    <img class="navbar-brand-full sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/logo.svg" alt="CoreUI Logo">
    <img class="navbar-brand-minimized sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/sygnet.svg" width="30" height="30" alt="iSupplier Logo">
  </a>
<ng-select *ngIf="employeeEntities.length > 1" class="entity-select" (change)="changeEntity($event)" [(ngModel)]="currentEntity" [clearable]="false">
  <ng-option *ngFor="let entity of employeeEntities" [value]="entity">
    {{entity.name}}
  </ng-option>
</ng-select>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown>
      <a class="nav-link cursor-pointer" data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false" dropdownToggle>
        <ngx-avatars [name]="username" [src]="imageSrc" bgColor="#3498DB1A" fgColor="#3498DB" size="35" textSizeRatio="2"></ngx-avatars>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <a class="dropdown-item" [routerLink]="['/profile/my-profile']">
          <fa-duotone-icon [icon]="faUser" class="pr-2"></fa-duotone-icon>
          <span>{{'core.components.header.navbar.dropdown.profile'|translate}}</span>
        </a>
        <a class="dropdown-item" (click)="logout()">
          <fa-duotone-icon [icon]="faLock" class="pr-2"></fa-duotone-icon>
          <span>{{'core.components.header.navbar.dropdown.logout'|translate}}</span>
        </a>
      </div>
    </li>
    <li class="nav-item d-md-down-none">
      <button class="nav-link btn-link" (click)="logout()">
        <fa-duotone-icon [icon]="faPowerOff" class="pr-2"></fa-duotone-icon>
      </button>
    </li>
  </ul>
</header>

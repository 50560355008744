import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../core/services/auth-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatestWith } from 'rxjs';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';


@Component({
  selector: 'cgu-modal',
  templateUrl: './cgu-modal.component.html',
  styleUrls: ['./cgu-modal.component.scss']
})
export class CguModalComponent implements OnInit, OnDestroy {

  cguToBeValidated?: boolean;
  source?: any;
  subscriptions: Subscription[] = [];


  constructor(public modalRef: BsModalRef,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.getCgu();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  refuseCgu() {
    let modalRefConfirm;

    this.subscriptions.push(this.translateService.get(['app.cgu-confirm-refuse']).subscribe((data: any) => {
        let initialState = {
          message: data['app.cgu-confirm-refuse'],
          blueStyle: true
        };
        modalRefConfirm = this.modalService.show(ModalConfirmComponent, {class: 'confirm-modal' ,initialState, backdrop: true});
      }));


      this.subscriptions.push(
        modalRefConfirm.content.actionConfirmed.subscribe(()=>{
          setTimeout(()=>{
            this.modalRef.hide();
            this.authService.logout();
          }, 500
          )
        })
      );

  }

  acceptCgu() {
    this.authService.acceptCgu().subscribe({
      next: () => {
        this.modalRef.hide();
      }
    });
  }

  getCgu() {
    this.authService.getCgu().subscribe(
      {
        next: (res: any) => {
          this.source = URL.createObjectURL(res);
        }
      }
    );
  }

}

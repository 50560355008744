import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../core/services/auth-service.service';
import { ListContentModel } from '../../shared/models/list-content.model';
import {EmployeeAdvancedSearchModel} from '../models/employee-advanced-search.model';
import {Authority, EmployeePostBody, EmployeeWithEntityModel} from '../models/employee-post-body.model';
import {ProjectManagerModel} from '../models/project-manager.model';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  mapAdvancedSearchForm(params: any): EmployeeAdvancedSearchModel {
    return new EmployeeAdvancedSearchModel(params);
  }

  getEmployeesByPage(page: number, size: number, sort: string, search: string, advancedSearch: EmployeeAdvancedSearchModel, isAdvancedSearchDisplayed: boolean): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.firstName) {
        params = params.set('firstName', advancedSearch.firstName);
      }
      if (advancedSearch.lastName) {
        params = params.set('lastName', advancedSearch.lastName);
      }
      if (advancedSearch.email) {
        params = params.set('email', advancedSearch.email);
      }
      if (isAdvancedSearchDisplayed && advancedSearch.disabled) {
        params = params.set('enabled', 'false');
      }
    }

    params = params.set('size', size.toString()).set('page', page.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.employeesList, {params})
      .pipe(
        map(data => {
          data.content.forEach(e => e.fullName = e.firstName + ' ' + e.lastName);
          return data;
        })
      );
  }

  mapFormValueToResource(value: any): EmployeeWithEntityModel {
    const params: EmployeeWithEntityModel = new EmployeeWithEntityModel();

    if (value.employeeId) {
      params.employeeId = value.employeeId;
    } else {
      params.firstName = value.firstName;
      params.lastName = value.lastName;
      params.email = value.email;
      params.phoneNumber = value.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
      params.title = value.title;
    }

    params.authorities = [];
    params.authorities.push(new Authority(value.authority.id, value.authority.name, this.authService.decodeToken('tenantId')));

    return params;
  }

  createEmployee(result) {
    return this.httpClient.post(environment.api_root + commonProperties.employeesList, result);
  }

  updateEmployee(params: EmployeeWithEntityModel): Observable<any> {
    return this.httpClient.patch(environment.api_root + commonProperties.employeeById.replace(':employeeId', params.employeeId), params);
  }

  getEmployeeById(employeeId: any) {
    return this.httpClient.get(environment.api_root + commonProperties.employeeById.replace(':employeeId', employeeId));
  }

  getMyProfile() {
    return this.httpClient.get(environment.api_root + commonProperties.myProfile.replace(':employeeId', this.authService.decodeToken('employeeId')));
  }

  getEmployeePictureByEmployeeId(employeeId: string) {
    return this.httpClient.get(environment.api_root + commonProperties.employeePictureById.replace(':employeeId', employeeId), {responseType: 'blob'});
  }

  deleteMyPicture() {
    return this.httpClient.delete<void>(environment.api_root + commonProperties.employeePictureById.replace(':employeeId', this.authService.decodeToken('employeeId')));
  }

  getMyPicture(): Observable<Blob> {
    const employeeId = this.authService.decodeToken('employeeId');
    const url = environment.api_root + commonProperties.employeePictureById.replace(':employeeId', employeeId);
    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  uploadMyAvatar(formData: any) {
    return this.httpClient.post(environment.api_root + commonProperties.myPicture.replace(':employeeId', this.authService.decodeToken('employeeId')), formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateEmployeeRoleByEmployeeId(employeeId: any, body) {
    return this.httpClient.put(environment.api_root + commonProperties.employeeRoleById.replace(':employeeId', employeeId), body);
  }

  updateMyProfile(body: any) {
    return this.httpClient.patch(environment.api_root + commonProperties.myProfile.replace(':employeeId', this.authService.decodeToken('employeeId')), body);
  }

  getEmployeeAuthorities() {
    return this.httpClient.get(environment.api_root + commonProperties.authorities);
  }

  getAllProjectManagers(search: string, page: number, size: number): Observable<ProjectManagerModel[]> {
    let params = new HttpParams();
    params = params.set('search', search).set('page', page.toString()).set('size', size.toString()).set('authority', 'PROJECT_MANAGER');
    return this.httpClient.get<ProjectManagerModel[]>(environment.api_root + commonProperties.projectManagers, {params});
  }

  testUserEmail(userEmail: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('email', userEmail);
    return this.httpClient.get(environment.api_root + commonProperties.userEmailTest, {params});
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(value: any): any {
    //Delete ALL the whitespaces
    return value ? value.replace(/\s/g, "") : "";
  }

}

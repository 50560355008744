import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth-service.service';
import { KpisService } from './kpis.service';
import { RoleService } from './role.service';

@Injectable()
export class KpisInterceptorService implements HttpInterceptor{

  constructor(private kpiService: KpisService,  private roleService: RoleService, public auth: AuthService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
        if (this.auth.isAuthenticated() && event instanceof HttpResponse && this.roleService.isRole('PROJECT_MANAGER')) {
            switch (true){
              case req.url.includes('activityReportMgtApi/fees'):
                this.kpiService.getSubmittedFeeCount();
                break;
              case req.url.includes('requestMgtApi/projects/'):
              case req.url.includes('activityReportMgtApi/activitysheet/'):
                this.kpiService.getSubmittedActivitySheetCount();
                break;
              case req.url.includes('activityReportMgtApi/on-calls'):
                this.kpiService.getSubmittedOnCallCount();
                break;
            }
        }
        return event;
    }));

}
}
